<template>
    <div class="page_container">
        <div class="item">
            <div class="label">定金最小金额</div>
            <div class="cotent">
                <el-input-number :min="0" v-model="depositLimit.val" :precision="2" :controls="false" />
            </div>
            <div class="opts" v-if="depositLimit.val !== depositLimit.default">
                <el-link @click="updateDeposit" type="primary">修改</el-link>
                <el-link @click="onCancel('depositLimit')" type="info">取消</el-link>
            </div>
        </div>
        <div class="item">
            <div class="label">机会转出</div>
            <div class="cotent">
                <el-switch v-model="selfFlag" active-color="#13ce66" @change="onSelfFlagChange" />
            </div>
        </div>
    </div>
</template>
<script>
import { saveSystemConfig, getSystemConfig } from '@/js/api/commonApi.js'

export default {
    data() {
        return {
            depositLimit: { val: 0, default: 0 },
            selfFlag: false,
        }
    },
    created() {
        this.getDepositConfig()
        this.getSelfFlag()
    },
    methods: {
        updateDeposit() {
            saveSystemConfig({
                func: 'trade',
                key: 'deposit_limit',
                value: parseInt(this.depositLimit.val * 100),
            }).then(() => {
                this.$message.success('修改成功')
                this.depositLimit = {
                    val: this.depositLimit.val,
                    default: this.depositLimit.val,
                }
            })
        },
        onCancel(key) {
            this[key]['val'] = this[key]['default']
        },

        onSelfFlagChange() {
            saveSystemConfig({
                func: 'oppo',
                key: 'self_transfer',
                value: this.selfFlag,
            }).then(() => {
                this.$message.success('修改成功')
                this.getSelfFlag()
            })
        },

        getDepositConfig() {
            getSystemConfig({ func: 'trade', key: 'deposit_limit' }).then((res) => {
                this.depositLimit = {
                    val: res.data.deposit_limit / 100,
                    default: res.data.deposit_limit / 100,
                }
            })
        },
        getSelfFlag() {
            getSystemConfig({ func: 'oppo', key: 'self_transfer' }).then((res) => {
                this.selfFlag = res.data.self_transfer === 'true'
            })
        },
    },
}
</script>
<style lang="less" scoped>
.page_container {
    min-width: 420px;
    padding: 24px 24px 6px;
    display: flex;
    flex-wrap: wrap;

    .item {
        flex-basis: 48%;
        margin-right: 2%;
        display: flex;
        align-items: center;
        margin-bottom: 24px;
    }
}
.item {
    .label {
        flex-shrink: 0;
        width: 100px;
        text-align: right;
    }
    .cotent {
        margin: 0 12px;
        flex-grow: 1;
        /deep/ .el-input-number {
            width: 100%;
            input {
                text-align: left;
            }
        }
    }
    .opts {
        width: 64px;
        flex-shrink: 0;
        display: flex;
        justify-content: space-between;
    }
}
</style>
<style lang="less" scoped>
@media screen and (max-width: 980px) {
    .page_container {
        min-width: 420px;
        .item {
            flex-basis: 100%;
            margin-right: 0;
        }
    }
}
</style>
